<script>
import InternalChatSideBar from './InternalChatSideBar.vue';
import cable from '../../../api/cable.js';
import { mapGetters } from 'vuex';
import axios from 'axios';

// const timestamp = Date.now();

export default {
  components: {
    InternalChatSideBar,
  },

  data() {
    return {
      isPrivateMessage: false,
      isSidebarOpen: false,
      isTeamChat: true,
      username: '',
      newMessage: '',
      InternalChat: null,
      messages: [],
      groupAgents: [],
      isLoggedIn: false,
      receiverId: null,
      user: { id: '', name: '', color: '' },
      websocket: null,
      colors: [
        'cadetblue',
        'darkgoldenrod',
        'cornflowerblue',
        'darkkhaki',
        'hotpink',
        'gold',
      ],
      colorMap: {},
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    unreadCount() {
      return this.$store.getters['agents/getUnreadCount'];
    },
    isGroupMessage() {
      return this.$store.getters['agents/getisGroupMessage'];
    },
    selectedAgent() {
      return this.$store.getters['agents/getSelectedAgent'];
    },
  },
  mounted() {
    this.user = {
      id: this.$store.state.userId, // Supondo que você armazena o ID do usuário no Vuex
      name: this.$store.state.userName, // Supondo que você armazena o nome do usuário no Vuex
      color: this.getRandomColor(),
    };

    this.subscribeToChat();
    this.fetchGroupAgents();
    this.loadGroupChat(this.accountId, this.currentUser.id);
    this.$store.commit('agents/SET_IS_GROUPMESSAGE', false);
  },

  methods: {
    beforeDestroy() {
      if (this.chatChannel) {
        this.chatChannel.unsubscribe();
      }
    },
    getAgentColor(agentId) {
      // Se o agente já tem uma cor, retorne-a
      if (this.colorMap[agentId]) {
        return this.colorMap[agentId];
      }
      // Caso contrário, associe uma nova cor e salve no colorMap
      const color =
        this.colors[Object.keys(this.colorMap).length % this.colors.length];
      this.colorMap[agentId] = color;
      return color;
    },
    setReceiverId() {
      this.isTeamChat = false;

      this.loadAgentChat(this.selectedAgent);
      this.beforeDestroy();
      this.subscribeToChat();
    },
    setNameReceiverId(agentName) {
      this.nameReceiverId = agentName; // Atualiza o receiverId com o ID do agente selecionado
    },
    selectGroupActive() {
      // this.receiverId = 0;
      this.isTeamChat = true;

      this.fetchGroupAgents();
      this.loadGroupChat(this.accountId);
      this.beforeDestroy();
      this.subscribeToChat();
    },

    subscribeToChat() {
      const room = this.selectedAgent === null ? 'general' : 'private';

      this.chatChannel = cable.subscriptions.create(
        {
          channel: 'InternalChatChannel',
          room: room,
          user_id: this.currentUser.id,
          account_id: this.accountId,
          receiver_id: this.selectedAgent,
        },

        {
          received: data => {
            this.messages.push({
              content: data.message,
              sender: data.user,
              sender_id: data.sender_id,
            });
          },
          speak: message => {
            this.chatChannel.perform('send_message', {
              message,
              user_id: this.currentUser.id, // ID do receptor (ou null se for grupo)
              account_id: this.accountId,
              receiver_id: this.selectedAgent,
            });
            // if(this.currentUser.id === this.selectedAgent){
            //     this.$store.commit('agents/SET_UNREAD_COUNT',  this.$store.state.agents.unreadCount + 1);
            // };
          },
        }
      );
    },

    sendMessage() {
      if (this.newMessage.trim() === '') return;
      this.chatChannel.speak(this.newMessage);
      this.newMessage = '';
    },

    emitNotification(data) {
      // Lógica para notificar a nova mensagem
      this.$emit('messageInternal', {
        isGroupMessage: data.isGroupMessage,
        isPrivateMessage: data.isPrivateMessage,
        // outros dados que você queira passar
      });
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeInternalChatPanel() {
      this.$emit('panel');
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    scrollScreen() {
      this.$nextTick(() => {
        const chatMessages = this.$refs.chatMessages;
        chatMessages.scrollTop = chatMessages.scrollHeight;
      });
    },
    fetchGroupAgents() {
      axios
        .get(`/agents?account_id=${this.accountId}`)

        .then(response => {
          this.groupAgents = response.data; // Atualiza a lista de agentes
        });
      // .catch(error => {
      //   console.error('Erro ao buscar agentes:', error);
      // });
    },
    loadAgentChat(agentId) {
      // Aqui você pode chamar uma API para buscar as mensagens do usuário atual com o agente
      axios
        .get(
          `/messages?user_id=${this.currentUser.id}&receiver_id=${agentId}&account_id=${this.accountId}`
        )
        .then(response => {
          this.messages = response.data; // Atualiza a lista de
        });
      // .catch(error => {
      //   console.error('Erro ao carregar conversas:', error);
      // });
      this.updateMessageNotifications(
        this.accountId,
        this.currentUser.id,
        1,
        agentId
      );
    },
    loadGroupChat(IDaccount, IDagent) {
      axios.get(`/messagesgroup?account_id=${IDaccount}`).then(response => {
        this.messages = response.data; // Atualiza a lista de
      });
      // .catch(error => {
      //   console.error('Erro ao carregar conversas:', error);
      // });

      this.updateMessageNotifications(
        IDaccount,
        IDagent,
        2,
        this.currentUser.id
      );
    },
    async updateMessageNotifications(accountId, agentID, type, userid) {
      try {
        // Fazendo a requisição para atualizar o campo 'read' das notificações no backend
        await axios.post('/internal_message_notification/mark_as_read', {
          account_id: accountId,
          received_id: agentID,
          source_type: type,
          user_id: userid,
        });
      } catch (error) {
        // console.error('Erro ao atualizar as notificações para lidas:', error);
      }
    },
  },
};
</script>

<template>
  <div class="modal-mask">
    <div
      v-on-clickaway="closeInternalChatPanel"
      class="flex-col h-[90vh] w-[32.5rem] flex justify-between z-10 shadow-md absolute bg-white dark:bg-slate-800 left-14 rtl:left-auto rtl:right-14"
    >
      <div
        class="flex flex-row items-center justify-between w-full px-1 pr-2 pt-5 pb-3 border-b border-solid border-slate-50 dark:border-slate-700"
      >
        <button @click="toggleSidebar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#62676a"
          >
            <path
              d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"
            />
          </svg>
        </button>
        <div class="flex items-center">
          <span class="text-xl font-bold text-slate-800 dark:text-slate-100">
            {{ $t('INTERNAL_PAGE.HEADER') }}
          </span>
        </div>
        <div class="flex gap-4">
          <woot-button
            color-scheme="secondary"
            variant="link"
            size="tiny"
            icon="dismiss"
            @click="closeInternalChatPanel"
          />
        </div>
      </div>

      <InternalChatSideBar
        v-if="isSidebarOpen"
        class="absolute inset-0 z-20 bg-white"
        @sidebarClose="isSidebarOpen = false"
        @agentSelected="setReceiverId"
        @agentName="setNameReceiverId"
        @selectGroup="selectGroupActive"
      />
      <div
        class="flex flex-col w-full h-full border-b border-solid border-slate-50 dark:border-slate-700"
      >
        <div
          v-if="isTeamChat"
          class="chat-messages flex-grow overflow-y-auto bg-gray-50 dark:bg-slate-800 p-4 rounded-t-md flex flex-col-reverse max-h-[calc(90vh-150px)]"
        >
          <div
            v-for="(message, index) in messages.slice().reverse()"
            :key="index"
            class="message"
          >
            <div
              v-if="message.sender_id === currentUser.id"
              class="message-received bg-slate-700 p-3 rounded-lg rounded-br-none mb-2 max-w-xs w-full"
            >
              <p class="text-white break-words">{{ message.content }}</p>
            </div>
            <div
              v-else
              class="message-sent bg-green-500/80 p-3 rounded-lg rounded-bl-none mb-2 max-w-xs w-full"
            >
              <p class="font-bold">
                {{
                  groupAgents.find(
                    agent => agent && agent.id === message.sender_id
                  )
                    ? groupAgents.find(agent => agent.id === message.sender_id)
                        .name
                    : 'Agente desconhecido'
                }}
              </p>
              <p class="text-white break-words">{{ message.content }}</p>
            </div>
          </div>
        </div>
        <div
          v-else
          class="chat-messages flex-grow overflow-y-auto bg-gray-50 dark:bg-slate-800 p-4 rounded-t-md flex flex-col-reverse max-h-[calc(90vh-150px)]"
        >
          <div
            v-for="(message, index) in messages.slice().reverse()"
            :key="index"
            class="message"
          >
            <div
              v-if="message.sender_id === currentUser.id"
              class="message-received bg-slate-700 p-3 rounded-lg rounded-br-none mb-2 max-w-xs w-full"
            >
              <p class="text-white break-words">{{ message.content }}</p>
            </div>
            <div
              v-else
              class="message-sent bg-green-500 p-3 rounded-lg rounded-bl-none mb-2 max-w-xs w-full"
            >
              <p class="text-white break-words font-bold">
                {{ nameReceiverId }}
              </p>
              <p class="text-white break-words">{{ message.content }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-4 w-full flex items-center bg-white dark:bg-slate-900 border-t border-slate-50 dark:border-slate-700 gap-5"
      >
        <textarea
          v-model="newMessage"
          class="flex-grow rounded-lg focus:outline-none focus:bg-slate-500 dark:bg-slate-700 bg-slate-500 text-white resize-none overflow-y-auto font-normal leading-6 h-22"
          rows="1"
          placeholder="Digite sua mensagem..."
          @keyup.enter="sendMessage"
        />
        <button
          class="bg-blue-500 text-white rounded-full p-4 transition hover:bg-blue-600 focus:bg-blue-600"
          @click="sendMessage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="35px"
            viewBox="0 -960 960 960"
            width="35px"
            fill="#62676a"
          >
            <path
              d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
textarea {
  margin-bottom: 0 !important;
}
.transform {
  transform: translateY(-1rem); /* Ajuste conforme necessário */
}
/* Estilo para a barra de rolagem no Webkit (Chrome, Safari) */
textarea::-webkit-scrollbar {
  width: 4px; /* Largura da barra de rolagem */
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Cor do botão da barra de rolagem */
  border-radius: 2px; /* Borda arredondada */
}

textarea::-webkit-scrollbar-track {
  background-color: transparent; /* Fundo da barra de rolagem */
}

/* Estilo para a barra de rolagem no Firefox */
textarea {
  scrollbar-width: thin; /* Estilo da barra de rolagem (thin, auto, none) */
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Cor do botão e do fundo */
}
/* Estilo para a barra de rolagem no Webkit (Chrome, Safari) */
.chat-messages::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Cor do botão da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
}

.chat-messages::-webkit-scrollbar-track {
  background-color: transparent; /* Fundo da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas do fundo */
}

/* Estilo para a barra de rolagem no Firefox */
.chat-messages {
  scrollbar-width: thin; /* Estilo da barra de rolagem (thin, auto, none) */
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Cor do botão e do fundo */
  display: flex;
  flex-direction: column-reverse; /* Inverte a direção das mensagens */
  overflow-y: auto; /* Permite rolagem, se necessário */
  max-height: 400px; /* Ajuste conforme necessário */
}

.message {
  display: flex;
  justify-content: flex-start; /* Para mensagens recebidas */
}

.message-received {
  align-self: flex-end; /* Alinha as mensagens enviadas à direita */
  margin-left: auto; /* Empurra para o lado direito */
}
</style>
