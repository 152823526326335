<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import InternalChat from './InternalChat.vue';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    InternalChat,
  },

  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showInternalChat: false,
      whoSendmessage: [],
    };
  },
  computed: {
    unreadCount() {
      return this.$store.getters['agents/getUnreadCount'];
    },
    isGroupMessage() {
      return this.$store.getters['agents/getisGroupMessage'];
    },
    isNewMessage() {
      return this.$store.getters['agents/getisNewMessage'];
    },
  },

  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggleAccounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('openNotificationPanel');
    },
    openInternalChat() {
      this.showInternalChat = true; // Exibe o InternalChat
    },
  },
};
</script>

<template>
  <div
    class="flex flex-col justify-between w-16 h-full bg-white border-r dark:bg-slate-900 border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0"
  >
    <div class="flex flex-col items-center">
      <Logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-10"
      />
      <PrimaryNavItem
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <button
        v-if="isGroupMessage || isNewMessage"
        class="internal-item bg-red-500"
        @click="openInternalChat"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#62676a"
        >
          <path
            d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"
          />
        </svg>
      </button>
      <button v-else class="internal-item" @click="openInternalChat">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#62676a"
        >
          <path
            d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"
          />
        </svg>
      </button>

      <PrimaryNavItem
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        open-in-new-page
        :to="helpDocsURL"
      />
      <NotificationBell @openNotificationPanel="openNotificationPanel" />
      <AgentDetails @toggleMenu="toggleOptions" />
      <OptionsMenu
        :show="showOptionsMenu"
        @toggleAccounts="toggleAccountModal"
        @showSupportChatWindow="toggleSupportChatWindow"
        @openKeyShortcutModal="$emit('openKeyShortcutModal')"
        @close="toggleOptions"
      />
    </div>
    <InternalChat v-if="showInternalChat" @panel="showInternalChat = false" />
  </div>
</template>

<style>
.size {
  width: 20px;
  height: 20px;
}
.internal-item {
  display: flex;

  align-items: center;
}
</style>
