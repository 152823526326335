<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      GroupMessage: false,
      whoSendMessage: [],
      isSidebarOpen: true,
      agents: [],
      selectedReceiverId: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      account: 'getCurrentAccount',
    }),
    unreadCount() {
      return this.$store.getters['agents/getUnreadCount'];
    },
    isGroupMessage() {
      return this.$store.getters['agents/getisGroupMessage'];
    },
    isAgentInSenderIds() {
      return agentId => {
        return (
          this.whoSendMessage &&
          this.whoSendMessage.data.sender_ids.includes(agentId)
        );
      };
    },
  },

  async mounted() {
    this.whoSendMessage = await this.findNotification(
      this.accountId,
      1,
      this.currentUser.id
    );

    this.fetchAgents(); // Carrega a lista de agentes ao montar o componente
    // this$parent.$on('messageInternal', this.notificationMessageInternal);
    this.$parent.$on('messageInternal', this.notificationMessageInternal);
  },
  beforeDestroy() {
    this.$parent.$off('messageInternal', this.notificationMessageInternal); // Limpa o evento quando o componente é destruído
  },

  methods: {
    startChatWithTeam() {
      this.$emit('sidebarClose');
      this.$store.commit('agents/SET_IS_GROUPMESSAGE', false);
      this.$store.commit('agents/SET_SELECTED_AGENT', null);
      this.$emit('selectGroup');
    },
    selectAgent(agentId, agentName) {
      this.$emit('sidebarClose');
      this.$store.commit('agents/SET_IS_NEWMESSAGE', false);
      this.$store.commit('agents/SET_SELECTED_AGENT', agentId);
      this.$emit('agentSelected');
      this.$emit('agentName', agentName);
    },
    // isAgentSender(agentId) {
    //   return this.unreadCount['newMessage'].some(message => message.sender_id === agentId);
    // },

    async findNotification(accountId, type, userid) {
      try {
        const response = await axios.get(
          '/internal_message_notification_private/who_send',
          {
            params: {
              account_id: accountId,
              source_type: type,
              user_id: userid,
            },
          }
        );
        return response;
      } catch (error) {
        // console.error('Erro ao atualizar as notificações para lidas:', error);
        return [];
      }
    },

    closeSidebar() {
      this.$emit('sidebarClose');
    },
    fetchAgents() {
      axios
        .get(`/agents?account_id=${this.accountId}`)

        .then(response => {
          this.agents = response.data;
        });
      // .catch(error => {
      //   console.error('Erro ao buscar agentes:', error);
      // });
    },
  },
};
</script>

<template>
  <div id="sidebar" class="sidebar opacity-90 dark:bg-slate-800">
    <div class="sidebar-header opacity-100 dark:bg-slate-800">
      <button id="removebt" @click.stop="closeSidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#62676a"
        >
          <path
            d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"
          />
        </svg>
      </button>
      <div class="flex items-center">
        <span class="text-xl font-bold text-slate-800 dark:text-slate-100" />
      </div>
    </div>
    <div class="sidebar-body">
      <span
        class="px-2 pt-1 my-2 text-lg font-semibold text-slate-700 dark:text-slate-200 relative z-10"
      >
        {{ $t('INTERNAL_PAGE.TITLEGROUP') }}
      </span>
      <ul>
        <li v-if="isGroupMessage" class="mb-0 ml-0 list-none">
          <button
            type="submit"
            class="button clear tiny secondary"
            @click.stop="startChatWithTeam"
          >
            <span class="button__content bg-red-500 text-left rtl:text-right">
              {{ account.name }}
            </span>
          </button>
        </li>
        <li v-else class="mb-0 ml-0 list-none">
          <button
            type="submit"
            class="button clear tiny secondary"
            @click.stop="startChatWithTeam"
          >
            <span class="button__content text-left rtl:text-right">
              {{ account.name }}
            </span>
          </button>
        </li>
      </ul>
      <span
        class="px-2 pt-1 my-2 text-lg font-semibold text-slate-700 dark:text-slate-200 relative z-10"
      >
        {{ $t('INTERNAL_PAGE.TITLECHAT') }}
      </span>
      <ul>
        <li v-for="agent in agents" :key="agent.id" class="mb-0 ml-0 list-none">
          <div v-if="agent.id != currentUser.id">
            <button
              v-if="isAgentInSenderIds(agent.id)"
              type="button"
              class="button clear tiny secondary"
              @click.stop="selectAgent(agent.id, agent.name)"
            >
              <span class="button__content bg-red-500 text-left rtl:text-right">
                {{ agent.name }}
              </span>
            </button>
            <button
              v-else
              type="button"
              class="button clear tiny secondary"
              @click.stop="selectAgent(agent.id, agent.name)"
            >
              <span class="button__content text-left rtl:text-right">{{
                agent.name
              }}</span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  height: 11.37%;
  padding: 20px 8px 12px 4px;
}
.sidebar-body {
  padding: 10px;
}
</style>
